import type { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next'
import { ListPostItem } from '../components/Posts/ListPostItem'
import { fetcher, getListWPPostURL, listWPPosts, ListWPPostsResponse } from '../libs/wp/fetchers'
import useSWR from 'swr'
import { getRevalidateTime } from '../libs/isr'
import { PostPagenation } from '../components/Posts/Pagenation'
import { useBookmark } from '../components/Bookmark/Provider'

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  posts,
  locale,
  totalPages,
  totalPosts,
}) => {
  const { canUseBookmark } = useBookmark()
  const { data: recentPosts } = useSWR<ListWPPostsResponse['posts']>(
    getListWPPostURL({ locale }),
    fetcher,
    {
      fallbackData: posts,
    },
  )
  return (
    <>
      <div className='mt-4'>
        <h1 className='sr-only'>Recent post</h1>
        <ul role='list' className='space-y-4'>
          {recentPosts && Array.isArray(recentPosts)
            ? recentPosts.map((post) => {
                if (!post) return null
                return (
                  <li key={post?.id} className=''>
                    <ListPostItem post={post} canUseBookmark={canUseBookmark} />
                  </li>
                )
              })
            : null}
        </ul>
      </div>
      <div className='mt-4'>
        <PostPagenation totalPages={totalPages} totalPosts={totalPosts} />
      </div>
    </>
  )
}

export default Home

export const getStaticProps: GetStaticProps<
  ListWPPostsResponse & {
    locale?: string
  }
> = async (context) => {
  const { locale } = context
  const data = await listWPPosts({ locale })

  return {
    revalidate: getRevalidateTime(1, 'hours'),
    props: {
      ...data,
      locale,
    },
  }
}
