import { BookmarkIcon } from '@heroicons/react/solid'
import { FC } from 'react'
import { useBookmark } from './Provider'

export const BookmarkButton: FC<{
  postId?: number
}> = ({ postId }) => {
  const { putBookmark, removeBookmark, bookmarks, canUseBookmark } = useBookmark()
  const bookmarked = !!bookmarks && !!bookmarks.find((bookmark) => bookmark.post_id === postId)
  const buttonClassName = bookmarked
    ? `bg-indigo-600 text-white hover:bg-indigo-700`
    : 'bg-white text-indigo-600 border-indigo-600 hover:text-indigo-800 hover:border-indigo-800'
  if (!canUseBookmark) return null
  if (!postId) return null
  return (
    <button
      type='button'
      className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md  ${buttonClassName}  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      onClick={() => {
        bookmarked ? removeBookmark(postId) : putBookmark(postId)
      }}
    >
      <BookmarkIcon className='-ml-1 mr-3 h-5 w-5' aria-hidden='true' />
      Bookmark
    </button>
  )
}
