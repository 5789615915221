import { IWPPost } from './interface'

export type WPPostProps = Pick<
  IWPPost,
  'title' | 'id' | 'slug' | 'date' | 'date_gmt' | 'excerpt' | '_embedded' | 'content'
>
export const reduceWPPostProps = (post: IWPPost): WPPostProps => ({
  id: post.id,
  excerpt: post.excerpt,
  _embedded: post._embedded,
  content: post.content,
  date: post.date,
  date_gmt: post.date_gmt,
  slug: post.slug,
  title: post.title,
})

export class WPPost {
  private readonly post: WPPostProps
  constructor(post: WPPostProps) {
    this.post = post
    this.post.content.rendered = this.replaceSiteURL(this.post.content.rendered)
  }

  private replaceSiteURL(content: string): string {
    return content.replace(/wp-api.wp-kyoto.net/gi, 'wp-kyoto.net')
  }

  get id() {
    return this.post.id
  }
  get slug() {
    return this.post.slug
  }
  get date() {
    return this.post.date
  }
  get dateGmt() {
    return this.post.date_gmt
  }

  get author() {
    return this.post._embedded.author[0]
  }

  get categories() {
    return this.post._embedded['wp:term'][0]
  }
  get title() {
    return this.post.title.rendered
  }
  get content() {
    return this.post.content.rendered
  }
  get excerpt() {
    return this.post.excerpt.rendered
  }
  get featuredImage() {
    if (!this.post._embedded['wp:featuredmedia']) return null
    return this.post._embedded['wp:featuredmedia'][0]
  }
}
