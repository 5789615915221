import dayjs from 'dayjs'
import Link from 'next/link'
import { FC } from 'react'
import { WPPost, WPPostProps } from '../../libs/wp/post.class'
import { BookmarkButton } from '../Bookmark/BookmarkButton'
import { FaustJSPostExcerpt } from '../FaustJS/Excerpt'
import { FaustJSPostImage } from '../FaustJS/PostImage'

export const ListPostItem: FC<{
  post: WPPostProps
  canUseBookmark?: boolean
}> = (props) => {
  const post = new WPPost(props.post)
  return (
    <section className='bg-white p-2 md:p-4 grid gap-5 md:grid-cols-6 rounded-lg shadow-lg overflow-hidden'>
      {post.featuredImage ? (
        <div className='md:col-span-2'>
          <FaustJSPostImage
            featuredImage={post.featuredImage}
            className='h-48 w-full object-cover'
          />
        </div>
      ) : null}
      <div className={`${post.featuredImage ? 'md:col-span-4' : 'md:col-span-6'} m-5 md:m-3`}>
        <div className='flex-1'>
          <p className='text-sm font-medium text-indigo-600'>
            {post.categories?.map((category) => {
              if (!category) return null
              return (
                <Link
                  href={`/category/${category.slug}`}
                  passHref
                  key={`post-${post.id}-category-${category.slug}`}
                >
                  <a className='hover:underline mr-2'>{category.name}</a>
                </Link>
              )
            })}
          </p>
          <Link href={`/${post.slug}`} passHref>
            <a className='block mt-2'>
              <h2
                className='text-xl font-semibold text-gray-900'
                dangerouslySetInnerHTML={{ __html: post.title }}
              />
              <FaustJSPostExcerpt className='mt-3 text-base text-gray-500' post={post} />
            </a>
          </Link>
        </div>
        <div className='mt-6 flex items-center'>
          <div className='flex-shrink-0'>
            <span className='sr-only'>{post.author?.name}</span>
          </div>
          <div className='flex w-full justify-between items-center space-x-1 text-sm text-gray-500'>
            <time dateTime={post.dateGmt || undefined}>
              Published at: {dayjs(post.date).format('YYYY/MM/DD')}
            </time>
            <span aria-hidden='true'>&middot;</span>
            {props.canUseBookmark ? <BookmarkButton postId={post.id} /> : null}
          </div>
        </div>
      </div>
    </section>
  )
}
