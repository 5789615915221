import { ComponentProps, FC } from 'react'
import { WPPost } from '../../libs/wp/post.class'

export const FaustJSPostExcerpt: FC<
  {
    post: Pick<WPPost, 'excerpt'>
  } & ComponentProps<'p'>
> = ({ post: { excerpt }, ...paragraphProps }) => {
  if (!excerpt) return null
  return (
    <div
      {...paragraphProps}
      dangerouslySetInnerHTML={{
        __html: excerpt,
      }}
    />
  )
}

FaustJSPostExcerpt.displayName = 'FaustJSPostExcerpt'
