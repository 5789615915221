import { ComponentProps, FC, memo, useMemo } from 'react'
import { WPPost } from '../../libs/wp/post.class'

type ImageProp = ComponentProps<'img'>
type FaustJSPostImageProps = Pick<WPPost, 'featuredImage'> & {
  defaultImageUrl?: string
} & Pick<ImageProp, 'className' | 'alt'>

export const useFaustJsPostImage = ({
  featuredImage,
  alt,
}: Pick<FaustJSPostImageProps, 'featuredImage' | 'alt'>) => {
  const imageProps: ImageProp | null = useMemo(() => {
    if (!featuredImage) return null
    const node = featuredImage
    if (!node) return null
    const props: ImageProp = {
      alt: '',
    }
    if (node.source_url) props.src = node.source_url

    if (node.media_details) {
      if (node.media_details.width) props.width = node.media_details.width
      if (node.media_details.height) props.height = node.media_details.height
    }
    //const srcSet = node.srcSet()
    //if (srcSet) props.srcSet = srcSet
    if (alt) {
      props.alt = alt
    } else {
      const title = node.title
      if (title) {
        props.alt = title.rendered
      } else {
        const caption = node.caption
        if (caption) props.alt = caption.rendered
      }
    }
    return props
  }, [featuredImage, alt])
  return {
    imageProps,
  }
}

export const FaustJSPostImage: FC<FaustJSPostImageProps> = memo(
  ({ featuredImage, defaultImageUrl, className, alt }) => {
    const { imageProps } = useFaustJsPostImage({
      featuredImage,
      alt,
    })
    if (!featuredImage) {
      if (!defaultImageUrl) return null
      return <img src={defaultImageUrl} alt='' className={className} />
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...imageProps} className={className} />
  },
)

FaustJSPostImage.displayName = 'FaustJSPostImage'
