import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import Link from 'next/link'
import { FC, useMemo } from 'react'
import { classNames } from '../../libs/classNames'

export const PostPagenation: FC<{
  totalPages?: number
  totalPosts?: number
  currentPage?: number
  pagePrefix?: string
}> = ({ totalPosts, totalPages, currentPage = 1, pagePrefix = '' }) => {
  const { prePages, postPages } = useMemo(() => {
    const prePages: number[] = []
    if (currentPage > 1) prePages.push(currentPage - 1)
    prePages.push(currentPage)
    if (totalPages && totalPages > currentPage + 1) prePages.push(currentPage + 1)
    if (totalPages && totalPages > currentPage + 2) prePages.push(currentPage + 2)

    const postPages = []
    if (totalPages && totalPages !== currentPage) {
      if (totalPages === currentPage) postPages.push(totalPages - 3)
      postPages.push(totalPages - 2)
      postPages.push(totalPages - 1)
      if (totalPages !== currentPage) postPages.push(totalPages)
    }

    return { prePages, postPages: postPages.filter((p) => !prePages.includes(p)) }
  }, [totalPages, currentPage])

  if (!totalPosts || !totalPages || !currentPage) {
    return null
  }

  if (totalPages < 2) return null

  const pathPrefix = [pagePrefix, 'pages'].filter(Boolean).join('/')

  return (
    <nav className='border-t border-gray-200 px-4 flex items-center justify-between sm:px-0'>
      {currentPage === 1 && false ? (
        <div className='-mt-px w-0 flex-1 flex'>
          <Link href={`/${[pathPrefix, currentPage - 1].filter(Boolean).join('/')}`} passHref>
            <a className='border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'>
              <ArrowNarrowLeftIcon className='mr-3 h-5 w-5 text-gray-400' aria-hidden='true' />
              Previous
            </a>
          </Link>
        </div>
      ) : null}
      <div className='md:-mt-px md:flex'>
        {currentPage > 3 && totalPages > 3 ? (
          <>
            <Link href={`/${pagePrefix}`} passHref key={`pager-zero`}>
              <a
                className={classNames(
                  currentPage === 1
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium',
                )}
              >
                1
              </a>
            </Link>
            <span className='border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'>
              ...
            </span>
          </>
        ) : null}
        {prePages.map((label) => {
          const href =
            label === 1 ? `/${pagePrefix}` : `/${[pathPrefix, label].filter(Boolean).join('/')}`
          return (
            <Link href={href} passHref key={`pager-${label}`}>
              <a
                className={classNames(
                  currentPage === label
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium',
                )}
              >
                {label}
              </a>
            </Link>
          )
        })}
        {totalPages > 6 && postPages.length > 0 ? (
          <span className='border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'>
            ...
          </span>
        ) : null}
        {totalPages > 3
          ? postPages.map((label) => {
              const href =
                label === 1 ? `/${pagePrefix}` : `/${[pathPrefix, label].filter(Boolean).join('/')}`
              return (
                <Link href={href} passHref key={`pager-${label}`}>
                  <a
                    className={classNames(
                      currentPage === label
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium',
                    )}
                  >
                    {label}
                  </a>
                </Link>
              )
            })
          : null}
      </div>
      {currentPage !== totalPages && false ? (
        <div className='-mt-px w-0 flex-1 flex'>
          <Link href={`/page/${currentPage + 1}`} passHref>
            <a className='border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'>
              Next
              <ArrowNarrowRightIcon className='ml-3 h-5 w-5 text-gray-400' aria-hidden='true' />
            </a>
          </Link>
        </div>
      ) : null}
    </nav>
  )
}
